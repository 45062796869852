@import '../../styles/helpers';

.footer {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 100px 72px 32px 72px;
  border-top: 1px solid;
  @include setProperty(border-image, (linear-gradient(to right, rgba(30, 30, 30, 0.4), #1E1E1E, rgba(30, 30, 30, 0.4)) 10), (linear-gradient(to right, rgba(234, 234, 234, 0.4), #EAEAEA, rgba(234, 234, 234, 0.4)) 10));
  @include setProperty(background, var(--dark-bg), var(--secondary-50));
  position: relative;


  @include media(portrait) {
    padding: 100px 60px 32px 60px;
  }

  @include media(mobile) {
    padding: 56px 24px;
  }

  &__main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1296px;
    flex-direction: column;
    gap: 64px;
    width: 100%;

    @include media(desktopBig) {
      max-width: 1296px;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;

    @include media(landscape) {
      display: grid;
      grid-template-columns: 180px 330px; 
      justify-content: space-between;
      row-gap: 72px;
    }

    @include media(portrait) {
      display: grid;
      grid-template-columns: 180px 328px;
      justify-content: space-between;
      row-gap: 72px;
    }

    @include media(mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 64px;
    }
  }

  &__companyInfo {
    width: 205px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include media(mobile) {
      align-items: center;
      width: 100%;
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__nameWrapper {
    display: flex;
    flex-direction: column;
    gap: 3px;

    @include media(mobile) {
      align-items: center;
    }
  }

  &__companyTitle {
    font-family: 'KharkivTone', sans-serif;
    color: var(--secondary-50);
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.228px;
    max-width: 175px;

    @include media(mobile) {
      text-align: center;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @include media(mobile) {
      align-items: center;
    }
  }

  &__city, 
  &__address,
  &__email,
  &__phoneNumber {
    color: var(--secondary-50);
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.24px;
  }

  &__email,
  &__phoneNumber {
    &:hover {
      text-decoration: underline;
    }
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-start;

    @include media(mobile) {
      height: 48px;
    }
  }

  &__info {
    flex-shrink: 0;

    @include media(portrait) {
      display: grid;
      gap: 0 64px;
      align-items: flex-start;
    }

    @include media(mobile) {
      display: grid;
      grid-template-columns: 1fr;
      align-items: flex-start;
      margin-right: 0;
      order: 2;
      margin-top: 100px;
    }

    &__block {
      margin-bottom: 64px;

      @include media(mobile) {
        text-align: center;
      }

    }

    &__text {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.02em;
      margin-bottom: 16px;
      font-family: 'KharkivTone', sans-serif;

      @include media(desktopHuge) {
        font-size: 16px;
        line-height: 1;
      }

      a {
        color: inherit;

        &:hover {
          @include setProperty(color, var(--primary-500), var(--primary-900));

          svg {
            opacity: 1;

            path {
              @include setProperty(fill, var(--primary-500), var(--primary-900));
            }
          }
        }
      }
    }
  }

  &__telegram {
    transition: none;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
    text-align: center;
    font-size: 12px;
    line-height: 1;

    &:hover span {
      text-decoration: none;
    }

    svg {
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }

    span {
      text-decoration: underline;
      margin-left: 8px;
    }
  }
}

.services {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;

  @include media(landscape) {
    order: 3;
    grid-column: 1 / -1;
  }

  @include media(portrait) {
    order: 3;
    grid-column: 1 / -1;
  }

  @include media(mobile) {
    order: -1;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 120px;
    row-gap: 13px;

    @include media(desktopSmall) {
      column-gap: 60px;
    }

    @include media(landscape) {
      white-space: nowrap;
      column-gap: 0;
      justify-content: space-between;
      grid-template-columns: repeat(3, auto);
    }

    @include media(portrait) {
      white-space: nowrap;
      column-gap: 0;
      justify-content: space-between;
      grid-template-columns: repeat(2, auto);
    }

    @include media(mobile) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 13px;
    }
  }

  &__title {
    width: 100%;
    font-family: 'KharkivTone', sans-serif;
    font-size: 20px;
    line-height: 24px;
    display: block;
    letter-spacing: 0.2px;
  }

  &__item {
    display: flex;

    &__name {
      @include setProperty(color, var(--secondary-50), var(--secondary-500));
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      letter-spacing: 0.24px;
      transition: all 0.3s;

      &:hover, 
      &:focus-visible {
        color: var(--primary-500);
        cursor: pointer;
      }

      @include media(tablet) {
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
      }
    }
  }
}

.copyright {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: 0.24px;

  @include media(mobile) {
    flex-direction: column-reverse;
    align-items: center;
    gap: 12px;
  }

  &__link {
    display: inline-block;
    @include setProperty(color, var(--secondary-50), var(--secondary-400));

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
      text-decoration: underline;
    }

    &:active {
      @include setProperty(color, var(--primary-200), var(--primary-500));
    }

    &:not(:first-child) {
      position: relative;

      &:before {
        content: '-';
        pointer-events: none;
        margin-right: 8px;
        margin-left: 8px;
        display: inline-block;
        @include setProperty(color, var(--secondary-50), var(--secondary-400));
      }
    }
  }
}

.socialsBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--margin-lg);

  @include media(landscape) {
    gap: 16px;
    order: 2;
    width: 100%;
  }

  @include media(portrait) {
    order: 2;
    width: 100%;
  }

  @include media(mobile) {
    align-items: center;
  }
}
