@import 'src/styles/helpers';

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px 52px;

  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
  width: 100%;
  transition: top 0.5s;

  &_hide {
    top: -104px
  }

  &_show {
    top: 0;
    background-color: var(--dark-bg);
    border-bottom: 1px solid var(--dark-line-low-accent);
  }

  @include media(landscape) {
    padding: 20px 24px;
  }

  @include media(portrait) {
    padding: 20px 24px;
  }

  @include media(mobile) {
    padding: 20px 24px;
  }



  &__rightBlock {
    display: flex;
    align-items: center;
    margin-right: 24px;

    @include media(tablet) {
      display: none;
    }
  }

  .contactUsButton {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 56px;
    border: 1px solid var(--primary-500);
    color: var(--primary-500);
    height: fit-content;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 23px;
    line-height: 20px;
    font-weight: 500;
    text-transform: none;
    transition: all 0.3s ease;

    cursor: pointer;

    &:hover {
      @include setProperty(background, var(--secondary-700), var(--secondary-50));
    }

    &:active {
      @include setProperty(background, var(--secondary-600), var(--secondary-100));
    }
    cursor: pointer;

    .icon {
      width: 24px;
      height: 24px;
    }

    @include media(tablet) {
      display: none;
    }

    @include media(mobile) {
      display: none;
    }
  }
}

.logo {
  margin-right: auto;
}

.menuButton {
  outline: none;

  @include media(desktopAll) {
    display: none;
  }

  &_active {

    .menuButton__line {
      @include setProperty(background, var(--primary-500), var(--primary-900));
      margin-left: 3px;

      &:first-child {
        transform-origin: top left;
        transform: rotate(30deg) translateY(-1.5px);
      }

      &:last-child {
        transform-origin: bottom left;
        transform: rotate(-30deg) translateY(1.5px);
      }
    }
  }

  &__line {
    width: 24px;
    height: 1px;
    @include setProperty(background, var(--secondary-200), var(--secondary-500));
    transition: all 0.3s ease;

    &:first-child {
      margin-bottom: 6px;
    }
  }
}
