@import "src/styles/helpers";

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  background: url("/media/menu_elipses_bg_mobile.png") no-repeat top left;
  @include setProperty(background-color, var(--dark-bg), var(--light-bg));
  z-index: 10;

  &_a {
    display: block;
  }

  @include media(desktopAll) {
    background: url("/media/menu_elipses_bg.png") no-repeat center 100%;
    display: grid;
    grid-template-columns: 386px 1fr;
  }
}

.closeButton {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 24px;
  height: 24px;
  @include setProperty(color, var(--secondary-200), var(--secondary-400));
  transition: color 0.3s ease;

  &:hover {
    @include setProperty(color, var(--secondary-50), var(--secondary-200));
  }

  @include media(desktopAll) {
    width: 24px;
    height: 24px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.menuLink {
  font-family: 'KharkivTone', sans-serif;
  font-size: 30px;
  line-height: 34px;
  text-transform: capitalize;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  transition: all 0.3s ease;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    @include setProperty(background, var(--dark-elipse-grain), var(--light-elipse-grain));
    border-radius: 50%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }

  &_active {
    padding-left: 20px;
    @include setProperty(color, var(--primary-500), var(--primary-900));

    &:before {
      opacity: 1;
    }

    &:after {
      content: '';
      position: absolute;
      left: -30px;
      top: -14px;
      background: url('/media/submenuTitleImage.svg') no-repeat center;
      width: 60px;
      height: 68px;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    transition: transform 0.3s ease;

    @include media(desktopAll) {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

button.menuLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 90px;
  padding: 92px 32px 48px;
  min-height: 100%;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  @include media(desktopAll) {
    @include setProperty(background, var(--secondary-800), transparent);
    @include setProperty(border-right, none, 1px solid var(--secondary-50));
    padding: 92px 76px 48px;
    position: relative;
    z-index: 2;
  }

  @keyframes sidebarFadeIn {
    from {
      opacity: 0;
      padding-top: 122px;
    }
    to {
      opacity: 1;
      padding-top: 92px;
    }
  }

  @keyframes sidebarFadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      padding-top: 122px;
    }
  }

  &__menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &_hidden {
    animation: sidebarFadeOut 0.5s ease forwards;
    position: absolute;

    @include media(desktopAll) {
      animation: none;
      position: static;
    }
  }

  &_visible {
    animation: sidebarFadeIn 0.5s ease forwards;
    position: absolute;

    @include media(desktopAll) {
      animation: none;
      position: static;
    }
  }
}

.socials {
  display: flex;
  gap: 23px;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.main {
  position: relative;

  &__content {
    opacity: 0;
    position: relative;

    @keyframes menuSlideDown {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
        padding-top: var(--margin-xl);
      }
    }

    @keyframes menuSlideUp {
      from {
        opacity: 1;
        padding-top: var(--margin-xl);
      }
      to {
        opacity: 0;
        padding-top: 0;
      }
    }

    @keyframes menuShowDesktop {
      from {
        opacity: 0;
        margin-left: -48px;
      }
      to {
        opacity: 1;
        margin-left: 0;
      }
    }

    @keyframes menuHideDesktop {
      from {
        opacity: 1;
        margin-left: 0;
      }
      to {
        opacity: 0;
        margin-left: -48px;
      }
    }

    &_visible {
      display: block;
      height: 100vh; // fallback
      height: calc(var(--vh, 1vh) * 100);
      width: 100%;
      animation: menuSlideDown 0.7s ease forwards;

      @include media(desktopAll) {
        animation-name: menuShowDesktop;
      }
    }

    &_hidden {
      animation: menuSlideUp 0.7s ease forwards;

      @include media(desktopAll) {
        animation-name: menuHideDesktop;
      }
    }
  }
}

.closeSubmenuButton {
  font-size: 16px;
  line-height: 1;
  font-weight: 300;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));

  &:before {
    content: '';
    width: 32px;
    height: 1px;
    display: inline-block;
    vertical-align: middle;
    @include setProperty(background, var(--secondary-300), var(--secondary-600));
    margin-right: 8px;
  }
}

.submenu {
  padding: 52px 32px 48px;
  margin-top: 20px;
  height: calc(100% - 40px);
  overflow-y: auto;

  @include media(desktopAll) {
    padding: 72px 0 48px;
    margin-top: 0;
    height: 100%;
  }

  .menuLink {

    &_active {
      margin-bottom: var(--margin-lg);
    }
  }

  &__content {
    display: grid;
    gap: var(--margin-lg);

    @include media(desktopAll) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      justify-content: center;
      padding: 0 15%;
      gap: var(--margin-xl)
    }
  }

  &__column {
    display: grid;
    gap: var(--margin-lg);

    @include media(desktopAll) {
      gap: var(--margin-xl)
    }
  }
}

.submenuItem {
  position: relative;

  &.active {

    .submenuItemButton {

      @include media(desktopAll) {
        @include setProperty(color, var(--primary-500), var(--primary-900));
      }
    }

    .submenuItemButton__icon {
      transform: rotate(90deg);
    }
  }
}

.submenuItemButton {
  font-family: 'KharkivTone', sans-serif;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @include media(desktopAll) {
    font-size: 24px;
    line-height: 32px;
  }


  &__link {
    text-decoration: underline;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }
  }

  &__icon {
    transition: transform 0.3s ease;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-left: 24px;
    @include setProperty(color, var(--secondary-50), var(--secondary-500));

    &:hover {
      @include setProperty(color, var(--primary-500), var(--primary-900));
    }

    @include media(desktopAll) {
      display: none;
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}

.submenuItemContentWrap {
  overflow: hidden;
}

.submenuItemContent {
  padding-top: var(--margin-lg);
  display: grid;
  gap: 20px;
  margin-top: -100%;
  transition: margin-top 0.5s;

  @include media(desktopAll) {
    margin-top: 0;
  }

  &.active {
    margin-top: 0;
  }
}

.submenuSubItem {
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  font-size: 16px;
  line-height: 1;

  &:hover {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.images {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  transition: opacity 0.4s ease;

  &_hidden {
    opacity: 0;
  }
}

.ellipse {
  position: absolute;

  &_draw {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    circle {
      stroke-width: 1px;
    }
  }

  &_border {
    top: 70%;
    left: 63%;
    transform: translate(-50%, -50%);
  }

  &_gradient {
    top: 40%;
    left: 63%;
    transform: translate(-50%, -50%);
  }

  &_noise {
    top: 60%;
    left: 70%;
    transform: translate(-50%, -50%);
  }
}
