@import "src/styles/helpers";

.contact {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh; // fallback
  height: calc(var(--vh, 1vh) * 100);
  @include setProperty(background, var(--dark-bg), var(--light-bg));
  padding: 120px 24px;
  overflow-y: auto;
  @include hideScrollbar;

  @include media(portrait) {
    padding: 160px 60px;
  }

  @include media(landscape) {
    padding: 160px 120px;
  }

  @include media(desktopAll) {
    padding: 200px 120px;
  }

  &__content {
    display: grid;
    align-items: stretch;
    position: relative;
    z-index: 1;

    @include media(landscape) {
      grid-template-columns: 300px 1fr;
      gap: 100px;
    }

    @include media(desktopAll) {
      grid-template-columns: 356px 480px;
      justify-content: space-between;
      margin: auto;
    }

    @include media(desktop) {
      max-width: 1040px;
    }

    @include media(desktopBig) {
      max-width: 1280px;
      grid-template-columns: 440px 527px;
    }

    @include media(desktopHuge) {
      max-width: 1280px;
    }
  }
}

.closeButton {
  position: fixed;
  z-index: 3;
  right: var(--margin-md);
  top: var(--margin-md);
  width: 40px;
  height: 24px;
  @include setProperty(color, var(--secondary-200), var(--secondary-200));

  &:hover {
    @include setProperty(color, var(--secondary-50), var(--secondary-500));
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.title {
  font-family: 'KharkivTone', sans-serif;
  font-size: 32px;
  line-height: 40px;
  @include setProperty(color, var(--secondary-50), var(--secondary-500));
  max-width: 230px;

  @include media(desktopAll) {
    max-width: none;
    margin-top: 50px;
  }

  @include media(desktopSmall) {
    font-size: 48px;
    line-height: 56px;
  }

  @include media(desktop) {
    font-size: 48px;
    line-height: 56px;
  }

  @include media(desktopBig) {
    font-size: 60px;
    line-height: 68px;
  }

  @include media(desktopHuge) {
    font-size: 60px;
    line-height: 68px;
  }

  mark {
    @include setProperty(color, var(--primary-500), var(--primary-900));
  }
}

.description {
  font-size: 14px;
  line-height: 2;
  font-weight: 300;
  margin-top: 40px;
}

.selectDropdown {
  box-shadow: 6px 7px 56px 11px rgba(0, 0, 0, 0.24) !important;
  @include setProperty(background, var(--dark-bg) !important, var(--light-bg) !important);

  :global {

    .MuiMenuItem-root {
      font-size: 13px !important;
      line-height: 16px !important;
      padding: 12px 24px !important;
      @include setProperty(color, var(--secondary-100) !important, var(--secondary-400) !important);
    }

    .MuiMenuItem-root.MuiButtonBase-root {
      border-bottom: 1px solid !important;
      font-family: 'Montserrat', sans-serif !important;
      @include setProperty(border-color, var(--dark-line-low-accent) !important, var(--light-line-low-accent) !important);

      &.Mui-selected {
        @include setProperty(background, var(--dark-line-low-accent) !important, var(--secondary-50) !important);
        @include setProperty(color, var(--secondary-50) !important, var(--secondary-500) !important);
      }
    }
  }
}

.form {
  display: grid;
  gap: calc(40px - 16px); //exclude Mui input label padding
  margin-top: var(--margin-xxl);

  @include media(portrait) {
    width: 100%;
    max-width: 527px;
    margin-left: auto;
    gap: calc(72px - 16px); //exclude Mui input label padding
  }

  @include media(landscape) {
    gap: calc(72px - 16px); //exclude Mui input label padding
    margin-top: -16px;
  }

  @include media(desktopAll) {
    gap: calc(72px - 16px); //exclude Mui input label padding
    margin-top: -16px;
  }

  :global {

    .MuiFormControl-root {
      margin: 0 !important;

      .MuiFormHelperText-root {
        font-family: 'Montserrat', sans-serif !important;
        position: absolute !important;
        top: 100% !important;
        bottom: auto !important;
        left: var(--margin-md) !important;
        @include setProperty(color, var(--dark-error) !important, var(--light-error) !important);
      }
    }

    .MuiInputLabel-root {
      color: var(--secondary-200) !important;
      left: 24px !important;
      right: 52px !important;
      font-family: 'Montserrat', sans-serif !important;
      font-size: 14px !important;
      line-height: 2 !important;
      font-weight: 300 !important;
      letter-spacing: var(--ls-183) !important;

      &.Mui-focused {
        @include setProperty(color, var(--primary-500) !important, var(--primary-900) !important);
      }
    }

    .MuiInput-input {
      @include setProperty(color, var(--secondary-100) !important, var(--secondary-400) !important);
      font-family: 'Montserrat', sans-serif !important;
      font-size: 14px !important;
      line-height: 2 !important;
      font-weight: 300 !important;
      padding: 0 var(--margin-md) !important;
      letter-spacing: 0.4px !important;

      &:not(.MuiInputBase-inputMultiline) {
        height: 40px !important;
      }

      &.MuiSelect-select {
        line-height: 40px !important;
      }
    }

    .MuiInputBase-root {

      &.Mui-focused {

        .MuiSelect-select {
          background: transparent !important;
        }
      }

      :local {
        .dropdownIcon {
          width: 24px;
          height: 24px;
          right: 24px;
          position: absolute;
          top: 2px;
          pointer-events: none;
        }
      }
    }

    .MuiInputBase-inputMultiline {
      min-height: 40px !important;
    }

    .MuiTextField-root {
      height: auto !important;

      &:hover {
        .MuiInputLabel-root:not(.Mui-focused) {
          @include setProperty(color, var(--secondary-100) !important, var(--secondary-400) !important);
        }
      }
    }

    .MuiInput-root {

      &:before {
        border-bottom: 1px solid var(--secondary-200) !important;
      }

      &:hover {

        &:before {
          @include setProperty(border-color, var(--secondary-100) !important, var(--secondary-400) !important);
        }
      }

      &.Mui-error {

        &:before, &:after {
          @include setProperty(border-bottom, 1px solid var(--dark-error) !important, 1px solid var(--light-error) !important);
        }
      }
    }
  }

  .footer {
    margin-top: calc(72px - 24px); // exclude grid gap
    display: grid;

    @include media(tablet) {
      margin-top: calc(100px - 56px); // exclude grid gap
    }

    @include media(desktopAll) {
      grid-template-columns: repeat(2, auto);
      align-items: flex-start;
      gap: 88px;
    }

    .status {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      letter-spacing: var(--ls-183);
      order: 1;
      margin-top: var(--margin-md);

      @include media(desktopAll) {
        margin-top: 0;
      }

      &.success {
        @include setProperty(color, var(--primary-500), var(--primary-900));
      }

      &.error {
        @include setProperty(color, var(--dark-error), var(--light-error));
      }
    }

    .sendButton {
      width: 100%;

      @keyframes spin {
        0% {
          transform: rotate(0);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      @include media(desktopAll) {
        width: 196px;
        order: 2;
        margin-left: auto;
        grid-column: 2 / 3;
      }

      img {
        animation: spin 1.5s linear infinite;
      }
    }
  }

}

.input {
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
}

textarea.input {
  resize: none;
}

.ellipses {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
  opacity: 0.3;

  * {
    pointer-events: none !important;
  }
}

.ellipse {
  position: absolute;

  &_draw {
    top: -119px;
    left: -231px;

    @include media(portrait) {
      top: -309px;
      left: -371px;
    }

    @include media(landscape) {
      top: -123px;
      left: -401px;
    }

    @include media(desktopAll) {
      top: -123px;
    }

    @include media(desktopSmall) {
      left: -240px;
    }

    @include media(desktop) {
      left: -164px;
    }

    @include media(desktopBig) {
      left: -58px;
    }

    @include media(desktopHuge) {
      left: -58px;
    }
  }

  &_border {
    left: 93px;
    top: 304px;

    @include media(portrait) {
      left: 138px;
      top: 356px;
    }

    @include media(landscape) {
      left: 143px;
      top: 587px;
    }

    @include media(desktopAll) {
      top: 587px;
    }

    @include media(desktopSmall) {
      left: 304px;
    }

    @include media(desktop) {
      left: 380px;
    }

    @include media(desktopBig) {
      left: 486px;
    }

    @include media(desktopHuge) {
      left: 486px;
    }
  }

  &_noise2 {
    top: 200px;
    left: -6px;

    @include media(portrait) {
      top: 192px;
      left: -18px;
    }

    @include media(landscape) {
      top: 412px;
      left: -24px;
    }

    @include media(desktopAll) {
      top: 412px;
    }

    @include media(desktopSmall) {
      left: 27px;
    }

    @include media(desktop) {
      left: 103px;
    }

    @include media(desktopBig) {
      left: 209px;
    }

    @include media(desktopHuge) {
      left: 209px;
    }
  }

  &_noise {
    top: 151px;
    right: -60px;

    @include media(portrait) {
      top: 115px;
      right: -100px;
    }

    @include media(landscape) {
      top: 116px;
      right: -130px;
    }

    @include media(desktopAll) {
      top: 116px;
    }

    @include media(desktopSmall) {
      right: -30px;
    }

    @include media(desktop) {
      right: -70px;
    }

    @include media(desktopBig) {
      right: 80px;
    }

    @include media(desktopHuge) {
      right: 80px;
    }
  }

  &_gradient {
    top: 112px;
    left: 244px;

    @include media(portrait) {
      top: 54px;
      left: 376px;
    }

    @include media(landscape) {
      top: 114px;
      left: 366px;
    }

    @include media(desktopAll) {
      top: 114px;
    }

    @include media(desktopSmall) {
      left: 527px;
    }

    @include media(desktop) {
      left: 603px;
    }

    @include media(desktopBig) {
      left: 710px;
    }

    @include media(desktopHuge) {
      left: 710px;
    }
  }

  &_gradient2 {
    bottom: -5px;
    right: -20px;

    @include media(portrait) {
      bottom: 10px;
      right: 55%;
    }

    @include media(landscape) {
      bottom: -20px;
      right: 30%;
    }

    @include media(desktopAll) {
      bottom: 75px;
    }

    @include media(desktopSmall) {
      right: 25%;
    }

    @include media(desktop) {
      right: 5%;
    }

    @include media(desktopBig) {
      right: 20%;
    }

    @include media(desktopHuge) {
      right: 20%;
    }
  }
}
