@import 'src/styles/helpers';


.menu {
  @include media(mobile) {
    display: none;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
  }

  &__item {
    font-weight: 500;
    
    &__button {
      cursor: pointer;
      font-family: 'Montserrat', sans-serif;
      @include setProperty(color, var(--secondary-200), var(--secondary-400));
      font-size: 14px;
      line-height: 20px;

      &:hover, &.active {
        @include setProperty(color, var(--primary-500), var(--primary-900));
        position: relative;
      }
    }
  }
}
