@import 'src/styles/helpers';

.social {
  @include setProperty(background, linear-gradient(90deg, #2A3634 0%, rgba(42, 54, 52, 0) 100%), linear-gradient(328.5deg, rgba(124, 124, 124, 0.4) 11.81%, rgba(204, 206, 206, 0.04) 88.09%));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 48px;
  max-height: 48px;
  overflow: hidden;
  width: 100%;
  height: 100%;

  @media (max-width: 374px) {
    max-width: 46px;
    max-height: 46px;
  }

  &__link {
    @include setProperty(background, var(--dark-bg), var(--secondary-50));
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
    @include setProperty(color, var(--primary-900), var(--secondary-200));
    padding: 12px;

    @media (max-width: 374px) {
      padding: 10px;
    }

    svg {
      z-index: 2;
      max-width: 24px;
      max-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      @include setProperty(color, var(--primary-500), var(--secondary-500));

      .social__link__particles {
        transform: none;
      }

      svg {

        path {
          @include setProperty(fill, var(--primary-500), var(--secondary-500));
        }
      }
    }

    &__particles {
      position: absolute;
      z-index: 1;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transform: rotate(-89deg);
      transform-origin: top right;
      transition: all 0.3s ease;
    }
  }

  svg path {
    @include setProperty(fill, var(--primary-900), var(--secondary-200));
  }
}
